import {createGlobalStyle} from 'styled-components'
import theme from './theme'

export const GlobalStyle = createGlobalStyle`
  body {
    color: ${theme.colors.light};
    background-color: ${theme.colors.dark};
    font-family: ${theme.fonts.body};
  }
  h1, h2, h3, h4, h5, h6 {
    font-family: ${theme.fonts.heading};
    font-weight: normal !important;
  }

  button{
    display: inline-block;
    border: none;
    padding: 1rem 2rem;
    margin: 0;
    text-decoration: none;
    background: transparent;
    color: #ffffff;
    font-family: sans-serif;
    font-size: 1rem;
    cursor: pointer;
    text-align: center;
    transition: background 250ms ease-in-out, transform 150ms ease;
    -webkit-appearance: none;
    -moz-appearance: none;
    &:focus{
      outline: 0;
    }
  }


  body > .shopify-buy-frame:not(:first-of-type) {
    display: none;
  }
  .shopify-buy-frame--cart{
    display: block !important;
  }
  .shopify-buy-frame--toggle{
    ${'' /* display: block !important; */}
  }
  .shopify-buy__product:not(:first-of-type){
      display: none !important;
  }
`

import React, {useState, useContext, useEffect} from 'react'
import * as Styled from './Navbar.style'
import LoopStatic from '../LoopStatic'
import theme from '../../styles/theme'
import {motion, AnimatePresence} from 'framer-motion'
import {Link} from 'gatsby'
import {useLocation} from '@reach/router'

import close from '../../images/close.svg'
import Context from '../../utils/context/Context'
import useWindow from '../../utils/hooks/useWindow'

export default function Navbar({light}) {
  const [isOpen, setIsOpen] = useState(false)
  const location = useLocation()
  const {
    shopClicked,
    setShopClicked,
    shopClickedFromProduct,
    setShopClickedFromProduct,
    indexHeight
  } = useContext(Context)
  const {isMobileView} = useWindow()

  useEffect(() => {
    if (
      (location.pathname === '/' && (shopClicked || shopClickedFromProduct)) ||
      location.search === '?shop=1'
    ) {
      console.log('SCROLL TO SHOP')
      setTimeout(() => {
        window.scrollTo({
          top:
            indexHeight !== 0
              ? indexHeight
              : isMobileView
              ? 701
              : window.innerHeight,
          behavior: shopClicked ? 'smooth' : 'instant'
        })
        setShopClicked(false)
        setShopClickedFromProduct(false)
      }, 100)
    }
  }, [
    shopClickedFromProduct,
    shopClicked,
    setShopClicked,
    indexHeight,
    isMobileView,
    location
  ])

  return (
    <Styled.Navbar $light={light}>
      <div className="inner" onClick={() => setIsOpen(!isOpen)}>
        <Link to="/" className="logoContainer">
          <LoopStatic dark={light} size="50px" />
          <h1>JACQUES</h1>
        </Link>
        <div
          className="button"
          onClick={() => setIsOpen(!isOpen)}
          role="presentation"
        >
          <AnimatePresence exitBeforeEnter>
            <motion.svg
              key="burger"
              initial={{opacity: 0}}
              animate={{opacity: isOpen ? 0 : 1}}
              exit={{opacity: 0}}
              viewBox="0 0 100 80"
              width="40"
              height="20"
            >
              <rect
                fill={light ? theme.colors.dark : theme.colors.light}
                width="100"
                height="10"
              ></rect>
              <rect
                fill={light ? theme.colors.dark : theme.colors.light}
                y="30"
                width="100"
                height="10"
              ></rect>
              <rect
                fill={light ? theme.colors.dark : theme.colors.light}
                y="60"
                width="100"
                height="10"
              ></rect>
            </motion.svg>
            <motion.img
              style={{filter: light ? 'invert(100%)' : 'invert(0%)'}}
              key="close"
              initial={{opacity: 0}}
              animate={{opacity: isOpen ? 1 : 0}}
              exit={{opacity: 0}}
              src={close}
              alt="close"
            />
          </AnimatePresence>
        </div>
        <ul className={isOpen ? 'menusContainer open' : 'menusContainer'}>
          <li>
            <Link
              to="/"
              onClick={(e) => {
                if (location.pathname === '/') e.preventDefault()
                setShopClickedFromProduct(true)
              }}
            >
              SHOP
            </Link>
          </li>
          <li>
            <Link to="/musique">MUSIQUE</Link>
          </li>
          <li>
            <Link to="/videos">VIDEOS</Link>
          </li>
          {/* <li>
            <Link to="/textes">TEXTES</Link>
          </li> */}
          <li>
            <Link to="/tour">TOUR</Link>
          </li>
          <li>
            <Link to="/contact">CONTACT</Link>
          </li>
        </ul>
      </div>
    </Styled.Navbar>
  )
}

import React from 'react'
import {motion} from 'framer-motion'
import * as Styled from './LoopStatic.style'

export default function LoopStatic({
  size = '100%',
  marginTop = '0',
  dark = false
}) {
  return (
    <Styled.LoopStatic
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 1566.5 798.1"
      viewBox="0 0 1566.5 798.1"
      style={{
        width: size,
        height: size,
        marginTop: marginTop,
        marginBottom: marginTop
      }}
    >
      <motion.path
        fill="none"
        stroke={dark ? '#000' : '#fff'}
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="70"
        d="M1504.8 367.2c-1.4 0-758.9 86-875.4 60.8-91.6-19.8-160.7-119.8-137.8-208.1C516.4 124.1 613 27.7 711.3 43.6c249.2 40.3 258.7 500 76.6 622.9-225.7 152.2-748.6 73-748.6 73"
      ></motion.path>
      <motion.path
        fill="none"
        stroke={dark ? '#000' : '#fff'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="70"
        d="M1358.4 279.4l167 84.2-153.5 151.3"
      ></motion.path>
    </Styled.LoopStatic>
  )
}

import {useState, useEffect} from 'react'
import theme from '../../styles/theme'
import {window} from 'browser-monads'

function useWindow() {
  const [isMobileView, setIsMobileView] = useState(
    window.innerWidth < theme.breakpoints.md
  )
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined
  })

  // console.log('isMobileView useWindow : ', isMobileView)

  useEffect(() => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight
    })

    function handleResize() {
      if (window.innerWidth < theme.breakpoints.md) {
        setIsMobileView(true)
      } else if (window.innerWidth >= theme.breakpoints.md) {
        setIsMobileView(false)
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight
        })
      }
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return {windowSize, isMobileView}
}

export default useWindow

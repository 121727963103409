import styled from 'styled-components'
import theme from '../../styles/theme'

export const Navbar = styled.div`
  background-color: ${({$light}) =>
    $light ? theme.colors.light : theme.colors.dark};
  border-bottom: 1px solid ${theme.colors.grey};
  border-color: ${({$light}) =>
    $light ? theme.colors.dark : theme.colors.light};
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  height: 50px;
  .inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: ${theme.contentWidth};
    padding: 0 ${theme.paddingAround};
    margin: 0 auto;
    height: 100%;
    .logoContainer {
      display: flex;
      gap: 1rem;
      align-items: center;
      h1 {
        margin: 0;
        color: ${({$light}) => ($light ? 'black' : theme.colors.light)};
      }
    }
    .button {
      position: relative;
      display: none;
      cursor: pointer;
      @media (max-width: ${theme.breakpoints.md}px) {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 100%;
      }
      svg,
      img {
        position: absolute;
        right: ${theme.paddingAround};
      }
    }
    .menusContainer {
      display: flex;
      gap: 3rem;
      align-items: center;
      padding: 0;
      margin: 0;
      @media (max-width: ${theme.breakpoints.lg}px) {
        gap: 2rem;
      }
      @media screen and (max-width: ${theme.breakpoints.md}px) {
        position: absolute;
        top: 51px;
        left: 0;
        gap: 0rem;
        width: 100vw;
        padding: 2rem 0;
        flex-direction: column;
        background-color: ${({$light}) =>
          $light ? theme.colors.light : theme.colors.dark};
        opacity: 0;
        transition: opacity 0.5s;
        pointer-events: none;
        &.open {
          opacity: 1;
          pointer-events: all;
        }
      }
      li {
        list-style-type: none;
        display: flex;
        flex-direction: column;
        a {
          color: ${({$light}) => ($light ? 'black' : theme.colors.light)};
          text-decoration: none;
          font-size: 1.2rem;
          @media (max-width: ${theme.breakpoints.lg}px) {
            font-size: 1rem;
          }
          @media (max-width: ${theme.breakpoints.md}px) {
            font-size: 1.2rem;
            padding: 1.5rem 3rem;
          }
        }
      }
    }
  }
`

import React, {createContext, useState, useEffect} from 'react'
import {graphql, useStaticQuery} from 'gatsby'
import {getImage} from 'gatsby-plugin-image'

var base64 = require('base-64')
const Context = createContext({})

function ContextProvider({children}) {
  const [products, setProducts] = useState([])
  const [shopClicked, setShopClicked] = useState(false)
  const [shopClickedFromProduct, setShopClickedFromProduct] = useState(false)
  const [indexHeight, setIndexHeight] = useState(0)
  const buyButtonOptions = {
    product: {
      iframe: false,
      contents: {
        img: false,
        title: false,
        price: true
      },
      text: {
        button: 'BUY NOW'
      },
      googleFonts: ['Arvo']
    },
    productSet: {
      styles: {
        products: {
          '@media (min-width: 601px)': {
            'margin-left': '-20px'
          }
        }
      }
    },
    modalProduct: {
      contents: {
        img: false,
        imgWithCarousel: true
      },
      styles: {
        product: {
          '@media (min-width: 601px)': {
            'max-width': '100%',
            'margin-left': '0px',
            'margin-bottom': '0px'
          }
        },
        button: {
          'font-family': 'Arvo, serif',
          ':hover': {
            'background-color': '#000000'
          },
          'background-color': '#000000',
          ':focus': {
            'background-color': '#000000'
          },
          'border-radius': '0px',
          'padding-left': '30px',
          'padding-right': '30px'
        },
        title: {
          'font-family': 'Helvetica Neue, sans-serif',
          'font-weight': 'normal',
          'font-size': '28px',
          color: '#000000'
        },
        price: {
          'font-family': 'Helvetica Neue, sans-serif',
          'font-weight': 'normal',
          'font-size': '24px',
          color: '#000000'
        },
        compareAt: {
          'font-family': 'Helvetica Neue, sans-serif',
          'font-weight': 'normal',
          'font-size': '20.4px',
          color: '#000000'
        },
        unitPrice: {
          'font-family': 'Helvetica Neue, sans-serif',
          'font-weight': 'normal',
          'font-size': '20.4px',
          color: '#000000'
        },
        description: {
          'font-family': 'Helvetica Neue, sans-serif',
          'font-weight': 'normal',
          'font-size': '16px',
          color: '#000000'
        }
      },
      googleFonts: ['Arvo'],
      text: {
        button: 'Add to cart'
      }
    },
    modal: {
      styles: {
        modal: {
          'background-color': '#fffdfd'
        }
      }
    },
    option: {
      styles: {
        label: {
          'font-family': 'Arvo, serif',
          color: '#000000'
        },
        select: {
          'font-family': 'Arvo, serif'
        }
      },
      googleFonts: ['Arvo']
    },
    cart: {
      styles: {
        button: {
          'font-family': 'Arvo, serif',
          ':hover': {
            'background-color': '#000000'
          },
          'background-color': '#000000',
          ':focus': {
            'background-color': '#000000'
          },
          'border-radius': '0px'
        },
        title: {
          color: '#000000'
        },
        header: {
          color: '#000000'
        },
        lineItems: {
          color: '#000000'
        },
        subtotalText: {
          color: '#000000'
        },
        subtotal: {
          color: '#000000'
        },
        notice: {
          color: '#000000'
        },
        currency: {
          color: '#000000'
        },
        close: {
          color: '#000000',
          ':hover': {
            color: '#000000'
          }
        },
        empty: {
          color: '#000000'
        },
        noteDescription: {
          color: '#000000'
        },
        discountText: {
          color: '#000000'
        },
        discountIcon: {
          fill: '#000000'
        },
        discountAmount: {
          color: '#000000'
        }
      },
      text: {
        total: 'Total',
        notice: '',
        button: 'PAY'
      },
      contents: {
        note: true
      },
      popup: false,
      googleFonts: ['Arvo']
    },
    toggle: {
      styles: {
        toggle: {
          'font-family': 'Arvo, serif',
          'background-color': '#000000',
          ':hover': {
            'background-color': '#000000'
          },
          ':focus': {
            'background-color': '#000000'
          }
        }
      },
      googleFonts: ['Arvo']
    },
    lineItem: {
      styles: {
        variantTitle: {
          color: '#000000'
        },
        title: {
          color: '#000000'
        },
        price: {
          color: '#000000'
        },
        fullPrice: {
          color: '#000000'
        },
        discount: {
          color: '#000000'
        },
        discountIcon: {
          fill: '#000000'
        },
        quantity: {
          color: '#000000'
        },
        quantityIncrement: {
          color: '#000000',
          'border-color': '#000000'
        },
        quantityDecrement: {
          color: '#000000',
          'border-color': '#000000'
        },
        quantityInput: {
          color: '#000000',
          'border-color': '#000000'
        }
      }
    }
  }

  // console.log(indexHeight)

  const graphql_products = useStaticQuery(graphql`
    {
      allShopifyProduct {
        edges {
          node {
            variants {
              price
            }
            title
            shopifyId
            descriptionHtml
            totalInventory
            images {
              product {
                featuredImage {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        placeholder: DOMINANT_COLOR
                        layout: FULL_WIDTH
                        formats: [PNG]
                        breakpoints: [550, 750, 1080, 1366, 1920]
                        quality: 100
                      )
                    }
                  }
                }
              }
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: DOMINANT_COLOR
                    layout: FULL_WIDTH
                    formats: [PNG]
                    breakpoints: [550, 750, 1080, 1366, 1920]
                    quality: 100
                  )
                }
              }
            }
          }
        }
      }
      allPrismicListeProduits {
        nodes {
          data {
            produits {
              bigwax_id
              titre {
                text
              }
            }
          }
        }
      }
    }
  `)

  // MERGE CONTENTFUL AND SHOPIFY PRODUCTS
  useEffect(() => {
    const backoffice_products =
      graphql_products.allPrismicListeProduits.nodes[0].data.produits

    const results = backoffice_products.map((backoffice_product) => {
      let id_b64 = base64.encode(
        'gid://shopify/Product/' + backoffice_product.bigwax_id
      )
      let id = 'gid://shopify/Product/' + backoffice_product.bigwax_id
      let current_shopify_product =
        graphql_products.allShopifyProduct.edges.filter((shopify_product) => {
          return shopify_product.node.shopifyId === id
        })
      if (current_shopify_product.length > 0) {
        return {
          titre: backoffice_product.titre,
          displayedTitle: current_shopify_product[0].node.title,
          id_bigwax: backoffice_product.bigwax_id,
          id_b64: id_b64,
          prix: current_shopify_product[0].node.variants[0].price,
          stock: current_shopify_product[0].node.totalInventory,
          featuredImage: getImage(
            current_shopify_product[0].node.images[0].product.featuredImage
              .localFile.childImageSharp
          ),
          images: current_shopify_product[0].node.images.map((image) =>
            getImage(image.localFile.childImageSharp.gatsbyImageData)
          ),
          description: current_shopify_product[0].node.descriptionHtml
        }
      } else {
        return null
      }
    })

    var cleanedResults = results.filter((result) => {
      return result !== undefined && result !== null
    })

    setProducts(cleanedResults)
  }, [graphql_products])

  return (
    <Context.Provider
      value={{
        products,
        buyButtonOptions,
        shopClicked,
        setShopClicked,
        shopClickedFromProduct,
        setShopClickedFromProduct,
        indexHeight,
        setIndexHeight
      }}
    >
      {children}
    </Context.Provider>
  )
}

export {ContextProvider}
export default Context

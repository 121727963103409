import * as React from 'react'
import './src/styles/normalize.scss'
import './static/fonts/fonts.css'
import {ContextProvider} from './src/utils/context/Context'
import {GlobalStyle} from './src/styles/global.style'

export const wrapRootElement = ({element}) => {
  return (
    <ContextProvider>
      <GlobalStyle />
      {element}
    </ContextProvider>
  )
}
export {wrapPageElement} from './src/utils/wrapPageElement.jsx'

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-musique-js": () => import("./../../../src/pages/musique.js" /* webpackChunkName: "component---src-pages-musique-js" */),
  "component---src-pages-tour-js": () => import("./../../../src/pages/tour.js" /* webpackChunkName: "component---src-pages-tour-js" */),
  "component---src-pages-videos-js": () => import("./../../../src/pages/videos.js" /* webpackChunkName: "component---src-pages-videos-js" */),
  "component---src-templates-product-page-product-page-component-js": () => import("./../../../src/templates/ProductPage/ProductPage.component.js" /* webpackChunkName: "component---src-templates-product-page-product-page-component-js" */),
  "component---src-templates-video-video-component-js": () => import("./../../../src/templates/Video/Video.component.js" /* webpackChunkName: "component---src-templates-video-video-component-js" */)
}


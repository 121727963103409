const theme = {
  contentWidth: '1200px',
  paddingAround: '3%',
  baseFontSize: 15,
  borderRadius: '10px',

  breakpoints: {
    xxs: 320,
    xs: 375,
    sm: 480,
    mm: 640,
    md: 768,
    lg: 1024,
    vl: 1280,
    xl: 1440,
    xxl: 1600,
    hd: 1920
  },

  colors: {
    primary: '#00bcd4',
    light: '#FFFFFF',
    dark: '#1C1C1D',
    grey: '#222222'
  },

  fonts: {
    heading: "'Riposte', sans-serif",
    body: "'MPRC', sans-serif"
  },

  fw: {
    black: 900,
    bold: 700,
    regular: 400
  },

  fs: {
    base: 16,
    paragraph: 15,
    big: 88,
    veryBigMobile: 56,
    veryBigTablet: 96,
    veryBig: 148
  },

  header: {
    bannersSize: 51,
    SideBarWidth: 165,
    SideBarWidthMobile: 110
  }
}

export default theme

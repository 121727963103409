import React from 'react'
import Navbar from '../components/Navbar'
import {useLocation} from '@reach/router'

const Layout = ({children}) => {
  const location = useLocation()
  return (
    <div
      style={{
        position: 'relative'
      }}
    >
      {location.pathname !== '/' && <Navbar />}
      {children}
    </div>
  )
}

export default Layout
